@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-action-btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 9em;
}
.ant-popover-buttons {
  display: flex;
}
.form-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 15px;
}
@media (max-width: 1035px) {
  .form-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 660px) {
  .form-container {
    grid-template-columns: 1fr;
  }
}
.form-container--two-column {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 660px) {
  .form-container--two-column {
    grid-template-columns: 1fr;
  }
}
.form-container--two-column .btn-container {
  grid-column: 0.3333333333;
}
.company-edit-box,
.user-form,
.agent-onboarding,
.onboarding-password-form,
.onboarding-link-form {
  background-color: white;
  border-radius: 0.125rem;
  border-width: 1px;
}
.btn-container {
  grid-column: 0.25;
  display: flex;
  margin-top: 20px;
  justify-self: end;
}
@media (max-width: 1035px) {
  .btn-container {
    grid-column: 0.3333333333;
 }
}
@media (max-width: 660px) {
  .btn-container {
    grid-column: 0.5;
 }
}
.ant-popconfirm-buttons,.ant-modal-confirm-btns,.ant-modal-footer{
  display: flex;
}
.date-control {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 8px 11px;
  &:hover {
    border: 1px solid #3FA9FF;
  }
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

:where(.css-dev-only-do-not-override-nllxry).ant-btn.ant-btn-sm {
  background-color: #0958d9 !important;
  color: #fff !important;
}

form :has(input:invalid) input{
  border: 1px solid red;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}